.particle-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    position: relative;
    &:before {
        filter: blur(5px) grayscale(50%);
        position: absolute;
        width: 100%;
        height: 100%;
        content: "";
        background-image: url('/src/assets/summer_field_at_night.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transform: scale(1.2);
        z-index: -1;
    }
    #particles-canvas {
        width: inherit;
        height: inherit;
        position: relative;
    }
}