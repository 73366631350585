@import url('https://fonts.googleapis.com/css?family=Cormorant+SC|Montserrat&display=swap');


body {
    background-color: $dark-gray;
    color: $light-gray;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    .font-black, .font-black a {
        color: $black!important;
    }
    main section:first-of-type:not(#banner) {
        padding-top: 96px;
    }
}

.bg-white {
    background-color: $white !important;
}
.bg-black {
    background-color: $black !important;
}