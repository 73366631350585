/** Variables **/
$asphalt-grey: #7f8c8d;
$black: #000000;
$white: #FFFFFF;
$green: #646568;
$grey-green: #416047;
$light-blue: #b3cde0;
$lapis: #1F4788;
$light-gray: #cccccc;
$gray: #585858;
$dark-gray: #262626;
$deep-red: #820000;
$green-haze: #019875;
$base-transition: .2s all;
$cubic-transition: all .3s cubic-bezier(1,.25,0,.75) 0s;
$othello-grey: #1E1E1E;