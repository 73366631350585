.scroll-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
    scroll-snap-points-y: repeat(100vh);
    scroll-snap-type: y mandatory;
    section {
        height: 100vh;
        scroll-snap-align: start;
        scroll-snap-stop: always;
        position: relative;
        overflow-y: scroll;
    }
}