#blog-listing-heading {
    position: fixed;
    z-index: 100;
}
section.blog-post {
    padding-top: 6rem;
    .post-content {
        .post-img {
            float: left;
            margin-right: 1rem;
            margin-bottom: 1rem;
            width: 100%;
            @include media-breakpoint-up(md) {
                width: 20rem;
            }
        }
        p {
            text-align: justify;
            a { 
                color: $white !important;
            }
        }
    }
}