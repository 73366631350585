/** Navbar Styles **/
@import "../bootstrap/variables";

.nav-wrap {
  z-index: 1000;
  color: $light-gray;
  font-weight: 600;
  display: flex;
  justify-content: end;
  padding-right: 0rem;
  padding-top: 1rem;
  @include media-breakpoint-up(lg) {
    padding-right: 2rem;
  }
  .nav-wrap-inner {
    position: relative;
  }

  .navbar-toggle-btn {
    background: unset;
    border: none;
    box-shadow: none;
    margin-right: 1rem;
    transform: scale(1.2);
    .navbar-toggler-icon {
      background-image: none;
      pointer-events: none;
      &.hamburger-icon {
        margin: 0 auto;
        width: 30px;
        height: 30px;
        position: relative;
        .bar {
          padding: 0;
          width: 30px;
          height: 4px;
          background-color: $light-gray;
          display: block;
          border-radius: 4px;
          transition: all 0.4s ease-in-out;
          position: absolute;
        }
        .bar1 {
          top: 2.5px;
        }
        .bar2, .bar3 {
          top: 12.5px;
        }
        .bar3 {
          right: 0;
        }
        .bar4 {
          bottom: 3px;
        }
        &.open {
          .bar {
            background-color: $white;
          }
          .bar1{
            transform: rotate(45deg);
            transform-origin: 14%;
            width: 41px
          }
          .bar2 {
            transform: translateX(-40px);
            background-color: transparent;
          }
          .bar3 {
            transform: translateX(40px);
            background-color: transparent;
          }
          .bar4 {
            transform-origin: 14%;
            transform: rotate(-45deg);
            width: 41px;
          }
        }
      }
    }
  }
  #navbarNav {
    text-align: right;
    padding-right: 0;
    right: -300px;
    &.nav-menu {
      overflow: hidden;
      transition: all .3s linear;
      position: absolute;
      top: 3rem;
      &.open {
          padding-right: 20rem;
      }
      .nav-link {
        padding-bottom: 0;
        margin-bottom: 1rem;
      }
    }
  }
}

/** End Navbar Styles **/