/** Slide In utility classes **/
.slide-outer {
    position: relative;
  }
  .slide-left-wrapper {
    position: absolute;
    width: 100%;
    .slide-content {
      h1, h2, h3, h4, h5, h6 {
        padding-left: 5px;
      }
    } 
  }
  
  .slide-content {
    position: absolute;
    left: -500px;
    width: 100%;
    -webkit-animation: slide 1s forwards;
    -webkit-animation-delay: 0s;
    animation: slide 1s forwards;
    animation-delay: 0s;
  }
  
  @-webkit-keyframes slide {
    100% {
      top: 0;
      left: 0;
    }
  }
  @keyframes slide {
    100% {
      top: 0;
      left: 0;
    }
  }

/** link underline effect classes **/
a {
    text-decoration: none;
    position: relative;
    text-transform: capitalize;
    display: inline-block;
    &:hover {
        text-decoration: none;
    }
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: $cubic-transition;
        transition: $cubic-transition;
    }
    &:hover:before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        border-bottom: 1px solid;
    }
}