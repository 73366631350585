/** Banner styles **/
#banner {
  position: relative;
}
.banner-content{
    margin-top: 2rem;
  }
  
  .banner-headline {
    color: $white;
    h2, h3{
      text-transform: uppercase;
      text-shadow: 0px 0px 10px rgba(70,70,70,0.9);
    }
    h2 {
      font-weight: 700;
      font-size: 4rem;
    }
    h3 {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 3rem;
    }
  }
  
  @supports (-webkit-appearance: none) {
    section#banner .img-background {
      background-image: url('/dist/assets/img/code-bg.jpg')!important;
    }
  }
  
  section#banner {
    overflow: hidden;
    .img-background {
        background-image: url('/dist/assets/img/code-bg.jpg');
        min-height: 800px;
        height: 70vh;
        }
    .slide-left-wrapper {
        margin-left: 10%;
    }
}
  
  .bio-img-wrap{
    border-radius: 5px;
  }
  
  .bio-img-wrap img{
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  
  
  @media (max-width: 1920px) {
    section#banner .img-background{
      min-height: 600px;
    }
  }
  
  @media (min-width: 1200px){
    .banner-content{
      margin-top: 0%;
    }
  }
  
  @media (max-width: 992px){
    section#banner .img-background{
      min-height: 500px;
      height: 50vh;
    }
  }