section#blog-listing {
  background-color: $dark-gray !important;
  .post-card {
    padding-left: 0px;
    background-color: $white;
    color: $dark-gray;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    transition: all .3s;
    &:hover {
      box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.75)
    }
  }
  .post-thumb {
    padding: 0!important;
  }
  .post-thumb-inner-wrap {
    width: 100%;
    height: 100%;
    padding: 0!important;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .post-snip{
    padding: 20px;
  }
  .post-title {
    .post-date {
        font-weight: 700;
    }
  }
}